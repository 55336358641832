const FB = () => {
  return (
    <div className="relative max-w-6xl min-w-6xl m-aut py-6 ">
      <iframe
        src="https://www.facebook.com/plugins/page.php?href=https://www.facebook.com/VelimaqSac/&tabs=timeline&width=1500&height=1800"
        width="1500"
        height="1800"
        style={{ border: "none", overflow: "hidden" }}
        frameborder="0"
        allowTransparency="true"
        title="Facebook Social Plugin"
        allow="encrypted-media"
      ></iframe>
    </div>
  );
};

export default FB;
