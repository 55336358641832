// SubHero Component
import React from "react";
import imgAncha from "../../assets/images/vlogs/ps2.webp";

const SubHero = () => {
  return (
    <div className="relative max-w-6xl m-auto h-[420px]">
      <div className="h-full overflow-hidden">
        <img
          src={imgAncha}
          alt="Construction Equipment Background"
          className="absolute h-[120px] md:h-full w-full object-cover md:object-center md:z-0 z-10"
        />
      </div>

      <div className="py-12 md:py-8 top-0 left-0 px-8 md:px-12 lg:px-16 max-w-xl absolute md:absolute z-20">
        <h1 className="text-2xl md:text-5xl font-bold mb-4">
          <span className="text-red-600">P</span>ortafolio de
          <br />
          <span className="text-red-600">P</span>royectos
        </h1>

        <p className="text-gray-800 text-base md:text-xl leading-relaxed">
          Si estas búsqueda de maquinaria para la ejecución de tu proyecto y
          necesitas un aliado que te acompañe y asesore desde el inicio hasta
          culminarlo COMUNICATE CON NOSOTROS AHORA
        </p>

        <a href="https://wa.me/+51947232440" target="_blank" rel="noopener noreferrer">
          <button className="mt-6 px-8 py-3 bg-gray-900 text-white rounded-full hover:bg-gray-800 transition-colors duration-300">
            Cotizar
          </button>
        </a>
      </div>
    </div>
  );
};

export default SubHero;
