// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import HomePage from "./components/HomePage/0HomePage";
import AboutUs from "./components/AboutUs/0AboutUs";
import Contact from "./components/Contact/0Contact";
import Footer from "./components/Footer";
import Products from "./components/Products/0Products";
import PrivacyPolicy from "./components/Policy/Policy";
import Vlog from "./components/VLog/0Vlog";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/nosotros" element={<AboutUs />} />
          <Route path="/contacto" element={<Contact />} />
          <Route path="/productos" element={<Products />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/vlog" element={<Vlog />} />
          <Route path="*" element={<HomePage />} /> {/* Ruta para manejar páginas no definidas */}
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
