// App Component - Componente principal
import React from "react";
import Hero from "./Hero";
import SubHero from "./SubHero";
import Portfolio from "./Portfolio";
import ProjectDetails from "./ProjectDetails";
import FB from "./FB";

const Vlog = () => {
  return (
    <div className="min-h-screen bg-white relative">
    <Navbar />
    <div className="grid grid-cols-12 m-auto">
      <div className="col-span-12 lg:col-span-8">
      <Hero />
      <SubHero />
      <Portfolio />
      <ProjectDetails />
      </div>
      <div className="col-span-3 hidden lg:block">
      <FB />
      </div>
    </div>
    </div>
  );
  };
  
  export default Vlog;

  // Navbar Component
  const Navbar = () => {
  return (
    <nav className="colorBgBlue shadow-lg h-36">        
    </nav>
  );
  };
