import React from "react";

// ProjectDetails Component
const ProjectDetails = () => {
  const maquinarias = [
    "MotoNiveladora",
    "Rodillo Compactador",
    "RetroExcavadora",
    "Grupo Electrógeno",
    "Cargador Frontal",
  ];

  return (
    <div className="max-w-6xl mx-auto p-6 flex gap-6">
      {/* Columna izquierda */}
      <div className="flex-1">
        <h1 className="text-3xl font-bold mb-6">Detalles del proyecto</h1>

        <div className="mb-6">
          <p className="text-gray-800 leading-relaxed">
            Proyecto de Construcción de Carretera en el Distrito de Ate: Un Paso
            Hacia el Desarrollo y la Conectividad El Distrito de Ate, ubicado en
            la región este de la ciudad de Lima, está experimentando un cambio
            significativo con el lanzamiento de un ambicioso proyecto de
            infraestructura vial: la cons- trucción de una nueva carretera que
            mejorará la conectividad y la movilidad en esta zona. Este proyecto
            no solo busca optimizar el tráfico vehicular, sino también impulsar
            el desarrollo económico y social en una de las áreas más dinámicas
            de la capital peruana.
          </p>
        </div>

        <div className="border border-dashed border-gray-300 p-4 mb-4">
          {/* Área para comentarios */}
        </div>

        <div className="flex items-center gap-4">
          <button className="bg-gray-800 text-white px-4 py-2 rounded">
            Comentar
          </button>
          <div className="flex items-center gap-2">
            <span>Compartir</span>
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24">
              <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z" />
            </svg>
          </div>
        </div>
      </div>

      {/* Columna derecha */}
      <div className="hidden md:block">
        <div className="w-80 bg-gray-50 p-6">
          <h2 className="text-2xl font-bold mb-6">Maquinarias</h2>
          <div className="space-y-4">
            {maquinarias.map((maquina, index) => (
              <div
                key={index}
                className="border-b border-gray-200 pb-2 text-gray-600"
              >
                {maquina}
              </div>
            ))}
          </div>
        </div>
      </div>          
    </div>
  );
};

export default ProjectDetails;
