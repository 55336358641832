import React from "react";
import { Link, useLocation } from "react-router-dom";

const HeaderNav = ({ classNav, claseUl, closeMobileMenu }) => {
  const location = useLocation();

  const estilo = " azonix text-2xl sm:text-2xl md:text-sm lg:text-lg xl:text-xl ";
  const estiloActivo = "text-red-600 underline";

  const getLinkStyle = (path) => {
    return `${estilo} ${location.pathname === path ? estiloActivo : 'text-white no-underline'}`;
  };

  return (
    <div className="flex pt-3">
      <nav className={`${classNav}`}>
        <ul className={`${claseUl}`}>
          <li onClick={closeMobileMenu}>
            <Link to="/" className={getLinkStyle('/')}>
              Inicio
            </Link>
          </li>
          <li onClick={closeMobileMenu}>
            <Link to="/nosotros" className={getLinkStyle('/nosotros')}>
              Nosotros
            </Link>
          </li>
          <li onClick={closeMobileMenu}>
            <Link to="/productos" className={getLinkStyle('/productos')}>
              Productos
            </Link>
          </li>
          <li onClick={closeMobileMenu}>
            <Link to="/contacto" className={getLinkStyle('/contacto')}>
              Contacto
            </Link>
          </li>
          <li onClick={closeMobileMenu}>
            <Link to="/vlog" className={getLinkStyle('/vlog')}>
              VLog
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HeaderNav;
