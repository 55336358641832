  // Hero Component
import React from "react";
import Titulo from "../../assets/images/vlogs/Titulo.svg";


  const Hero = () => {
    return (
      <div className="pl-1 max-w-6xl m-auto">
        <div className="">
          <img src={Titulo} alt="Titulo" className="w-full md:w-[70vw] text-left" />
        </div>
      </div>
    );
  };

  export default Hero;