// Portfolio Component
import React, { useState, useEffect } from "react";
import maq1 from "../../assets/images/vlogs/324.webp";
import maq2 from "../../assets/images/vlogs/325.webp";
import maq3 from "../../assets/images/vlogs/326.webp";


const Portfolio = () => {
    const [currentGroup, setCurrentGroup] = useState(0);
    const [itemsPerGroup, setItemsPerGroup] = useState(3);
    
    const updateItemsPerGroup = () => {
      setItemsPerGroup(window.innerWidth < 768 ? 1 : 3);
      setCurrentGroup(0);
    };

    useEffect(() => {
        updateItemsPerGroup();
        window.addEventListener('resize', updateItemsPerGroup);
        return () => window.removeEventListener('resize', updateItemsPerGroup);
    }, []);

    const projects = [
      {
        id: 1,
        image: maq1,
        title: "Proyecto Ate",
        description: "Proyecto de construcción de carretera en el distrito de Ate"
      },
      {
        id: 2,
        image: maq2,
        title: "Proyecto Carabayllo",
        description: "Proyecto de construcción de campo de golf en el distrito de Carabayllo"
      },
      {
        id: 3,
        image: maq3,
        title: "Proyecto Pucusana",
        description: "Proyecto de construcción de casa modelo en el distrito de Pucusana"
      }
    ];
  
    const totalGroups = Math.ceil(projects.length / itemsPerGroup);
  
    const nextSlide = () => {
      setCurrentGroup((prev) => (prev === totalGroups - 1 ? 0 : prev + 1));
    };
  
    const prevSlide = () => {
      setCurrentGroup((prev) => (prev === 0 ? totalGroups - 1 : prev - 1));
    };
  
    return (
      <div className="relative max-w-6xl mx-auto p-4 bg-gradient-to-b from-neutral-500 to-black">
      <div className="flex items-center justify-between">
        {/* Left Arrow */}
        <button 
        onClick={prevSlide}
        className="absolute left-2 md:-left-6 z-10 p-2 text-white bg-black bg-opacity-50 rounded-full hover:bg-opacity-75"
        >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
        </svg>
        </button>
    
        {/* Slides Container */}
        <div className="flex overflow-hidden w-full">
        <div 
          className="flex transition-transform duration-500 ease-in-out w-full"
          style={{ transform: `translateX(-${currentGroup * 100}%)` }}
        >
          {Array.from({ length: totalGroups }).map((_, groupIndex) => (
          <div key={groupIndex} className="flex w-full flex-shrink-0 gap-4">
            {projects
            .slice(groupIndex * itemsPerGroup, (groupIndex + 1) * itemsPerGroup)
            .map((project) => (
              <div key={project.id} className={`${itemsPerGroup === 1 ? 'w-full' : 'w-1/3'}`}>
              <div className="border-2 border-[#e10f1a] rounded-lg overflow-hidden">
                <img
                src={project.image}
                alt={project.title}
                className="w-full h-72 object-cover bg-white"
                />
                <div className="p-4">
                <div className="inline-block bg-[#e10f1a] shadow-[6px_6px_0px_0px_rgba(0,0,0,1)] text-white px-4 py-2 text-lg font-bold mb-2">
                  {project.title}
                </div>

                <div className="w-[70%] h-[6px] bg-[#e10f1a] shadow-[6px_4px_0px_0px_rgba(0,0,0,1)] my-2"></div>

                <p className="text-white text-sm">
                  {project.description}
                </p>
                </div>
              </div>
              </div>
            ))}
          </div>
          ))}
        </div>
        </div>
    
        {/* Right Arrow */}
        <button 
        onClick={nextSlide}
        className="absolute right-2 md:-right-6 z-10 p-2 text-white bg-black bg-opacity-50 rounded-full hover:bg-opacity-75"
        >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
        </svg>
        </button>
      </div>
    
      {/* Dots Indicator */}
      <div className="flex justify-center mt-4 gap-2">
        {Array.from({ length: totalGroups }).map((_, index) => (
        <button
          key={index}
          onClick={() => setCurrentGroup(index)}
          className={`h-3 w-3 rounded-full ${
          currentGroup === index ? 'bg-red-500' : 'bg-gray-300'
          }`}
        />
        ))}
      </div>
      </div>
    );
  }

  export default Portfolio;