import React from "react";
import SuccesStoriesImages from "./SuccesStoriesImages"

const SuccesStories = () => {
  return (
    <section id="about-us" className="colorBgWhite py-12">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-2">Casos de Éxito</h2>
          <p className="text-lg mb-2">
            Estos son algunos de los clientes que confían en nosotros y a los
            que hemos ayudado a crecer, construyendo relaciones sólidas en el
            largo plazo.
          </p>
          <div className="colorFontRed text-lg">★★★★★</div>
        </div>
        <SuccesStoriesImages />
      </div>
      
    </section>
  );
};

export default SuccesStories;
